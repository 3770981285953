<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import type {News} from "~/types/models";
import type {NewsResponse} from "~/types/responses";
import {QueueListIcon} from "@heroicons/vue/24/outline";

const news = ref<News[]>([]);
const {data, error}: { data: Ref<NewsResponse>, error: any } = await useFetch(useRuntimeConfig().public.baseURL + '/news/');
if (!error.value) {
  news.value = data.value.data
} else {
  Sentry.captureException(error.value)
}
</script>

<template>
  <ul class="flex flex-wrap justify-center gap-4 mt-4 sm:mt-8 bg-primary-600 p-4 py-12 my-6">
    <li v-for="article in news" :key="article.slug" class="w-[30rem]">
      <NuxtLink :to="'/news/' + article.id + '-' + article.slug" class="bg-gray-50 dark:bg-zinc-800 p-4 shadow flex rounded-lg group dark:hover:bg-zinc-800/60 hover:bg-gray-100 cursor-pointer">
        <div class="flex justify-center items-center p-8">
          <QueueListIcon class="text-primary-500 group-hover:text-primary-600 h-14 w-14 sm:group-hover:rotate-6 transition-transform transform ease-in-out"/>
        </div>
        <div class="flex flex-col gap-2">
          <h3 class="font-medium text-gray-900 dark:text-white">{{ article.name }}</h3>
          <p class="text-gray-600 dark:text-gray-300">
            {{ article.short_description }}
          </p>
          <p class="text-sm text-primary-500 hover:text-primary-600 mt-2 group-hover:text-primary-600">
            Visit the blog
          </p>
        </div>
      </NuxtLink>
    </li>
  </ul>

</template>